<template>
  <div class="social-login">
    <button v-if="false" @click="azureLogin" class="btn btn-color-pink mt-2">{{ type === 'register' ? 'Registreer' : 'Log in' }} via Keeo</button>
    <button @click="facebookLogin" class="btn btn-color-blue">{{ type === 'register' ? 'Registreer' : 'Log in' }} via Facebook</button>
  </div>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <LoadPopup v-if="showLoader" />
  </Transition>
</template>

<script>
import LoadPopup from "@/components/popups/LoadPopup";
export default {
  name: "SocialLogin",
  components: {LoadPopup},
  props : {
    type : { type : String, required : false, default : 'login'}
  },
  data() {
    return {
      showLoader : false,
    }
  },
  methods : {
    async azureLogin() {
      this.showLoader = true;
      this.$store.dispatch('user/azureLogin').then(
          () => {
            if (!this.$store.getters['user/user'].registrationCompleted) {
              this.showLoader = false;
              this.$router.push({name: 'register', params: { thirdParty : true}});
            } else {
              this.showLoader = false;
              this.$router.push({ name: 'popular'});
            }
          },
          error => {
            this.showLoader = false;
            this.error =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
          }
      );
    },
    async facebookLogin() {
      this.showLoader = true;
      this.$store.dispatch('user/facebookLogin').then(
          () => {
            this.showLoader = false;
            this.$router.push({ name: 'popular'});
          },
          error => {
            this.showLoader = false;
            this.error =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
          }
      );
    },
  }
}
</script>
